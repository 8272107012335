<template>
  <v-container fill-height>
    <v-layout justify-center align-center>
      <v-flex xs5 sm5 md5 lg3 xl3>
        <template>
          <v-alert type="error" v-if="mostraAlertLicenca">
            {{ texAlertLicenca }}
          </v-alert>
        </template>
        <v-card class="elevation-12">
          <v-toolbar
            color="blue-grey darken-1"
            class="white--text text-h8 text-capitalize"
            dark
          >
            <v-toolbar-title>
              <v-icon class="mb-1 mr-4" large> mdi-cloud-outline </v-icon>
              Clomos - Login
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-progress-circular
              v-show="isLoading"
              indeterminate
              color="white"
              width="2"
            ></v-progress-circular>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                prepend-icon="person_outline"
                name="usuario"
                label="Usuário"
                type="text"
                :error-messages="usuarioErrors"
                :success="!$v.user.usuario.$invalid"
                v-model.trim="$v.user.usuario.$model"
              ></v-text-field>
              <v-text-field
                prepend-icon="lock"
                name="senha"
                label="Senha"
                :error-messages="senhaErrors"
                :success="!$v.user.senha.$invalid"
                type="password"
                v-model.trim="$v.user.senha.$model"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <span class="text--disabled text-h7 text-capitalize">{{
              ambiente + "." + version
            }}</span>
            <v-spacer></v-spacer>
            <v-btn
              class="white--text"
              :disabled="$v.$invalid"
              color="blue-grey darken-1"
              @click="submit"
              >Login</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
const { mapActions } = createNamespacedHelpers("login");
import { version } from "/package.json";

export default {
  name: "Login",
  // mixins: [],
  data: () => ({
    error: undefined,
    isLoading: false,
    user: {
      usuario: "",
      senha: "",
    },
    ambiente: process.env.NODE_ENV,
    version: version,
    texAlertLicenca: "",
    varAlertLicenca: "",
    mostraAlertLicenca: false,
  }),

  validations: {
    user: {
      usuario: {
        required,
        minLength: minLength(6),
      },
      senha: {
        required,
        minLength: minLength(6),
      },
    },
  },

  mounted() {
    if (typeof this.$route.query.licenca != "undefined") {
      this.actLicenca(this.$route.query.licenca);
    } else if (window.location.hostname.split(".")[0] == "localhost") {
      this.actLicenca("dev");
    } else {
      // this.alertLicenca("danger", "Licença Inválida.");
      this.actLicenca(window.location.hostname.split(".")[0]);
    }
  },

  computed: {
    usuarioErrors() {
      const errors = [];
      const usuario = this.$v.user.usuario;
      if (!usuario.$dirty) {
        return errors;
      }
      !usuario.required && errors.push("Usuario Obrigatorio");
      !usuario.minLength && errors.push("Usuário muito curto");
      return errors;
    },
    senhaErrors() {
      const errors = [];
      const senha = this.$v.user.senha;
      if (!senha.$dirty) {
        return errors;
      }
      !senha.required && errors.push("Senha Obrigatorio");
      !senha.minLength && errors.push("Senha muito curta");
      return errors;
    },
  },

  methods: {
    ...mapActions(["actLogin", "actLicenca", "actPermissoes"]),

    alertLicenca(v, t) {
      this.varAlertLicenca = v;
      this.texAlertLicenca = t;
      this.mostraAlertLicenca = true;
    },

    alertLogin(v, t) {
      this.varAlertLogin = v;
      this.texAlertLogin = t;
      this.mostraAlertLogin = true;
    },

    async submit() {
      this.isLoading = true;
      try {
        await this.actLogin({
          data: this.$data,
          licenca: this.$store.state.login.licenca,
        });
        await this.metPegaPermissoes();
        this.$router.push(this.$route.query.redirect || "/aplicacao");
      } catch (error) {
        // console.log(error);
        this.$root.snackbar.show({
          type: "warning",
          message: error.message,
        });
        this.error = error.message;
        this.isLoading = false;
      }
    },

    async metPegaPermissoes() {
      // this.$token = this.$store.state.login.token; // Variavel global não usada ainda declarada no main.js
      window.is_token = this.$store.state.login.token; // Variavel no browser de validade global
      await this.actPermissoes({
        idUsuario: this.$store.state.login.usuario.id,
        licenca: this.$store.state.login.licenca,
      });
    },
  },
};
</script>
